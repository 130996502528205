<template>
  <div class="content-wrapper">
    <section class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1>Subscription</h1>
          </div>
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">
                <router-link :to="{name: 'Home'}">Home</router-link>
              </li>
              <li class="breadcrumb-item active">Subscription</li>
            </ol>
          </div>
        </div>
      </div>
    </section>

    <section class="content">
        <div class="container-fluid">
          <div class="card" id="Subscription">
            <div class="overlay" v-if="is_loading">
              <i class="fas fa-2x fa-sync-alt fa-spin"></i>
            </div>
            <form @submit.prevent="handleSubmission()" method="POST" novalidate>
              <div class="card-header">
                <h3 class="card-title" v-if="!is_edit">Create</h3>
                <h3 class="card-title" v-if="is_edit">Edit</h3>
                <div class="card-tools">
                  <router-link class="btn btn-info" :to="{name: 'SubscriptionList'}"><i class="fas fa-list"></i> List
                  </router-link>
                </div>
              </div>
              <div class="card-body">
                <div class="row">
                    <div class="col-8">


            <div class="form-group required" >
              <label class="control-label">User-Id</label>
              <input type="text" id="userId" name="userId"
                     v-bind:class="{'has-error' : errors.has('userId')}"
                     v-validate="'required'"
                     v-model="value.userId" class="form-control userId"
                     placeholder="User-Id">
              <div class="help text-danger" v-show="errors.has('userId')">
                {{ errors.first("userId") }}
              </div>
            </div>
            <div class="form-group required" >
              <label class="control-label">Subscription Plan</label>
              <input type="text" id="subscriptionPlanId" name="subscriptionPlanId"
                     v-bind:class="{'has-error' : errors.has('subscriptionPlanId')}"
                     v-validate="'required'"
                     v-model="value.subscriptionPlanId" class="form-control subscriptionPlanId"
                     placeholder="Subscription Plan">
              <div class="help text-danger" v-show="errors.has('subscriptionPlanId')">
                {{ errors.first("subscriptionPlanId") }}
              </div>
            </div>
            <div class="form-group required" >
              <label class="control-label">Base Subscription-Plan</label>
              <input type="text" id="baseSubscriptionPlanId" name="baseSubscriptionPlanId"
                     v-bind:class="{'has-error' : errors.has('baseSubscriptionPlanId')}"
                     v-validate="'required'"
                     v-model="value.baseSubscriptionPlanId" class="form-control baseSubscriptionPlanId"
                     placeholder="Base Subscription-Plan">
              <div class="help text-danger" v-show="errors.has('baseSubscriptionPlanId')">
                {{ errors.first("baseSubscriptionPlanId") }}
              </div>
            </div>
            <div class="form-group required" >
              <label class="control-label">Subscribe At</label>
              <input type="text" id="subscribeAt" name="subscribeAt"
                     v-bind:class="{'has-error' : errors.has('subscribeAt')}"
                     v-validate="'required'"
                     v-model="value.subscribeAt" class="form-control subscribeAt"
                     placeholder="Subscribe At">
              <div class="help text-danger" v-show="errors.has('subscribeAt')">
                {{ errors.first("subscribeAt") }}
              </div>
            </div>
            <div class="form-group " >
              <label class="control-label">Expire At</label>
              <input type="text" id="expireAt" name="expireAt"


                     v-model="value.expireAt" class="form-control expireAt"
                     placeholder="Expire At">

            </div>
            <div class="form-group " >
              <label class="control-label">Renew At</label>
              <input type="text" id="renewAt" name="renewAt"


                     v-model="value.renewAt" class="form-control renewAt"
                     placeholder="Renew At">

            </div>
            <div class="form-group " >
              <label class="control-label">Notify At</label>
              <input type="text" id="notifyAt" name="notifyAt"


                     v-model="value.notifyAt" class="form-control notifyAt"
                     placeholder="Notify At">

            </div>
            <div class="form-group " >
              <label class="control-label">Unsubscribe At</label>
              <input type="text" id="unsubscribeAt" name="unsubscribeAt"


                     v-model="value.unsubscribeAt" class="form-control unsubscribeAt"
                     placeholder="Unsubscribe At">

            </div>
            <div class="form-group " >
              <label class="control-label">Subscription Channel</label>
              <input type="text" id="subChannel" name="subChannel"


                     v-model="value.subChannel" class="form-control subChannel"
                     placeholder="Subscription Channel">

            </div>
            <div class="form-group " >
              <label class="control-label">Unsubscription Channel</label>
              <input type="text" id="unsubChannel" name="unsubChannel"


                     v-model="value.unsubChannel" class="form-control unsubChannel"
                     placeholder="Unsubscription Channel">

            </div>
            <div class="form-group required" >
              <label class="control-label">Auto Renew</label>
              <input type="text" id="autoRenew" name="autoRenew"
                     v-bind:class="{'has-error' : errors.has('autoRenew')}"
                     v-validate="'required'"
                     v-model="value.autoRenew" class="form-control autoRenew"
                     placeholder="Auto Renew">
              <div class="help text-danger" v-show="errors.has('autoRenew')">
                {{ errors.first("autoRenew") }}
              </div>
            </div>
            <div class="form-group " >
              <label class="control-label">App-Id</label>
              <input type="text" id="appId" name="appId"


                     v-model="value.appId" class="form-control appId"
                     placeholder="App-Id">

            </div>
                    </div>
                </div>
              </div>
              <div class="card-footer">
                <button type="submit" v-if="!is_edit" class="btn btn-primary">Submit</button>
                <button type="submit" v-if="is_edit" class="btn btn-primary">Update</button>
              </div>
            </form>
          </div>
        </div>
    </section>
  </div>
</template>

<script>

import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import axios from "axios";

export default {
  name: "SubscriptionForm",
  components: {
    DatePicker
  },
  data: () => ({
    isSelectorLoading: false,
    is_loading: false,
    is_edit: false,
    params: {},

    value: {


    },
    pre: {

    }
  }),
  methods: {
    handleSubmission() {
      let _this = this;
      this.$validator.validateAll().then((result) => {
        if (result) {
          _this.is_loading = true;
          let api_url = "/subscriptions";
          let method = "POST";
          if (_this.is_edit) {
            api_url = "/subscriptions/" + _this.$route.params.id
            method = "PUT";
          }
          axios({
            method: method, url: api_url,
            data: _this.value,
            headers: {"Content-Type": "application/json"},
          }).then(function (response) {
            _this.$toaster.success(response.data.message)
            _this.$router.push({"name": "SubscriptionList"});
          }).catch((errors) => {
            _this.$setErrorsFromResponse(errors.response.data);
          }).finally(() => {
            _this.is_loading = false;
          });
        }
      });
    },
    getDetail: function (id) {
      return new Promise(resolve => {
        let api_url = "/subscriptions/" + id;
        let _this = this;
        axios.get(api_url)
          .then(response => {
            _this.value = response.data;
            resolve(response.data);
          })
          .catch((error) => {
            console.log("error " + error);
            resolve(error);
          });
      });
    },
    async initialize() {
      this.is_loading = true;
      this.params = this.$route.params;

      if (!_.isEmpty(this.params) && this.params.id !== undefined) {
        this.is_edit = true;
      }
      if (this.is_edit) {
        await this.getDetail(this.params.id);
      }
      this.is_loading = false;
    }
  },
  mounted() {
    this.initialize()
  },
}
</script>

